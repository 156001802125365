
import { defineComponent } from "vue-demi";
import { ShopCartIcon } from '@/plugins/icons';
import { mapGetters } from "vuex";

export default defineComponent({
  emits: ['clickElement'],
  name: 'HeaderCart',
  props: {
    mobile: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    route() {
      this.$emit('clickElement');
      this.$router.push('/cart');
    }
  },
  computed: {
    ...mapGetters(['productQuantity']),
  },
  components: {
    ShopCartIcon
  },
})
