
import { currentNavigation } from "@/store/common/navigation";
import { useAPI } from "@/use";
import { defineComponent } from "vue-demi";

export default defineComponent({
  name: 'ProfileHeader',
  props: {
    isPartner: {
      type: Boolean,
      required: true
    },
    isUser: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      navigation: currentNavigation('profile'),
    }
  },
  methods: {
    async updateToPartner() {
      const result = await useAPI().user.updatePartnerMethod();
      this.$store.commit('updateUserDataModel', result.data);
    }
  }
})
