
import { defineComponent } from 'vue-demi';
import ProfileHeader from '@/components/profile/header/index.vue';
import { mapGetters } from 'vuex';
import { ROLES } from '@/utils/enums';
import { User } from '@/interfaces/user/user.dto';

export default defineComponent({
  name: "profile-layout",
  computed: {
    ...mapGetters(['user']),
    isPartner() {
      const user = this.user as User.Dto;
      return user.role === ROLES.PARTNER;
    },
    isUser() {
      const user = this.user as User.Dto;
      return user.role === ROLES.USER;
    }
  },
  components: {
    ProfileHeader
  }
});
