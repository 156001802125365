
import { defineComponent } from "vue-demi";

import CommonHeader from "../components/common/header/index.vue";
import CommonChatButton from "../components/common/chat/Button.vue";
import CommonFooter from '@/components/common/footer/index.vue';
import CommonChat from "../components/common/chat/index.vue";

import ProfileLayout from './ProfileLayout.vue';
import { mapGetters } from "vuex";

export default defineComponent({
  name: "main-layout",
  created() {
    this.$store.dispatch("updateProductCartQuantity");
  },
  computed: {
    ...mapGetters(['chat']),
    isMainSection() {
      return ['catalog', 'main', 'partner', 'about'].includes(this.$route.meta.section as string);
    },
    isProfileSection() {
      return this.$route.meta.section === 'profile';
    }
  },
  components: {
    CommonHeader,
    CommonChat,
    CommonChatButton,
    CommonFooter,
    ProfileLayout
  },
});
