import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, resolveDynamicComponent as _resolveDynamicComponent, Transition as _Transition, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/figures/logo.svg'
import _imports_1 from '@/assets/figures/main.svg'


const _withScopeId = n => (_pushScopeId("data-v-0be6da82"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "figures figures--logo",
  src: _imports_0,
  alt: ""
}
const _hoisted_2 = {
  key: 1,
  class: "figures figures--auth",
  src: _imports_1,
  alt: ""
}
const _hoisted_3 = { class: "page" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_common_header = _resolveComponent("common-header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_profile_layout = _resolveComponent("profile-layout")!
  const _component_common_footer = _resolveComponent("common-footer")!
  const _component_common_chat_button = _resolveComponent("common-chat-button")!
  const _component_common_chat = _resolveComponent("common-chat")!

  return (_openBlock(), _createElementBlock("main", null, [
    (_ctx.isMainSection)
      ? (_openBlock(), _createElementBlock("img", _hoisted_1))
      : (_openBlock(), _createElementBlock("img", _hoisted_2)),
    _createVNode(_component_common_header),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.isProfileSection)
        ? (_openBlock(), _createBlock(_component_profile_layout, { key: 0 }, {
            default: _withCtx(() => [
              _createVNode(_component_router_view)
            ]),
            _: 1
          }))
        : (_openBlock(), _createBlock(_component_router_view, {
            key: 1,
            class: "page--container"
          }, {
            default: _withCtx(({ Component }) => [
              _createVNode(_Transition, {
                name: "route",
                mode: "out-in"
              }, {
                default: _withCtx(() => [
                  (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
                ]),
                _: 2
              }, 1024)
            ]),
            _: 1
          }))
    ]),
    _createVNode(_component_common_footer),
    _createVNode(_component_common_chat_button),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.chat)
          ? (_openBlock(), _createBlock(_component_common_chat, { key: 0 }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}