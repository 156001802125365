
import { defineComponent } from "vue-demi";
import { mapGetters } from "vuex";
import HeaderProfile from "./modules/Profile.vue";
import HeaderCart from "./modules/Cart.vue";
import HeaderNavigation from "./modules/Navigation.vue";
import { LogotypeIcon, CancelIcon } from "@/plugins/icons";

export default defineComponent({
  name: "CommonHeader",
  data() {
    return {
      headerMode: false,
      burger: false
    };
  },
  mounted() {
    window.addEventListener("scroll", this.scroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.scroll);
  },
  computed: {
    ...mapGetters(['user']),
  },
  methods: {
    scroll() {
      this.headerMode = window.scrollY > 40;
    },
    toggleBurger() {
      this.burger = !this.burger;
    },
    clickElement() {
      this.burger = false;
    }
  },
  components: {
    HeaderProfile,
    HeaderNavigation,
    HeaderCart,
    LogotypeIcon,
    CancelIcon,
  },
});
