
import { defineComponent } from "vue-demi";
import { ChatIcon } from '@/plugins/icons';

export default defineComponent({
  name: 'ChatButton',
  methods: {
    openChat() {
      this.$store.commit('createChat');
    },
  },
  components: {
    ChatIcon
  }
})
