
import { defineComponent } from "vue-demi";
import { currentNavigation, Navigation } from '@/store/common/navigation';

export default defineComponent({
  emits: ['clickElement'],
  name: 'HeaderNavigation',
  props: {
    headerMode: {
      type: Boolean,
      required: true
    },
    mobile: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      navigation: currentNavigation('main'),
    }
  },
  methods: {
    isExactActive(element: Navigation) {
      if (element.route === '/' && this.$route.meta.section === 'main') return true;
      return element.route.includes(String(this.$route.meta.section));
    },
    navigate(element: Navigation) {
      this.$emit('clickElement');
      return this.$router.push(element.route);
    }
  }
})
