import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-dcb835e2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex items-center" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("section", {
    class: _normalizeClass(["navigation", { fixed: _ctx.headerMode, mobile: _ctx.mobile }])
  }, [
    _createElementVNode("ul", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navigation, (element) => {
        return (_openBlock(), _createBlock(_component_router_link, {
          to: element.route,
          key: element.route,
          custom: ""
        }, {
          default: _withCtx(() => [
            _createElementVNode("li", {
              class: _normalizeClass(["cursor fz18", { active: _ctx.isExactActive(element) }]),
              onClick: ($event: any) => (_ctx.navigate(element))
            }, _toDisplayString(element.name), 11, _hoisted_2)
          ]),
          _: 2
        }, 1032, ["to"]))
      }), 128))
    ])
  ], 2))
}