
import { defineComponent } from "vue-demi";
import {
  LogotypeIcon,
  VkIcon,
  OkIcon,
  TelegramIcon,
  InstagramIcon,
} from "@/plugins/icons";

export default defineComponent({
  name: "CommonFooter",
  methods: {
    route(route: string) {
      this.$router.push(route);
    },
  },
  components: {
    LogotypeIcon,
    VkIcon,
    OkIcon,
    TelegramIcon,
    InstagramIcon,
  },
});
