import { resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-226502fc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "avatar" }
const _hoisted_2 = {
  key: 0,
  alt: ""
}
const _hoisted_3 = { class: "name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_empty_image_icon = _resolveComponent("empty-image-icon")!
  const _component_down_arrow_icon = _resolveComponent("down-arrow-icon")!
  const _directive_lazy = _resolveDirective("lazy")!

  return (_openBlock(), _createElementBlock("section", {
    class: _normalizeClass(["profile", { mobile: _ctx.mobile }])
  }, [
    (_ctx.user)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "logging cursor btn btn--main flex items-center",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.switchShowMenu && _ctx.switchShowMenu(...args)))
        }, [
          _createElementVNode("div", _hoisted_1, [
            (_ctx.user.avatar)
              ? _withDirectives((_openBlock(), _createElementBlock("img", _hoisted_2, null, 512)), [
                  [_directive_lazy, _ctx.currentImageSize(_ctx.user.avatar.src, 50, 50)]
                ])
              : (_openBlock(), _createBlock(_component_empty_image_icon, {
                  key: 1,
                  color: "#fff"
                }))
          ]),
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.currentUserName), 1),
          _createVNode(_component_down_arrow_icon, { color: "#fff" })
        ]))
      : (_openBlock(), _createElementBlock("button", {
          key: 1,
          class: "btn btn--main",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.route('/auth')))
        }, "Войти"))
  ], 2))
}