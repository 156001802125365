import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3a7793c0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "profile" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_profile_header = _resolveComponent("profile-header")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_profile_header, {
      isPartner: _ctx.isPartner,
      isUser: _ctx.isUser
    }, null, 8, ["isPartner", "isUser"]),
    _createVNode(_component_router_view, { user: _ctx.user }, {
      default: _withCtx(({ Component }) => [
        _createVNode(_Transition, {
          name: "route",
          mode: "out-in"
        }, {
          default: _withCtx(() => [
            (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
          ]),
          _: 2
        }, 1024)
      ]),
      _: 1
    }, 8, ["user"])
  ]))
}