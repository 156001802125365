import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-06ae0df4"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "fz18"
}
const _hoisted_2 = {
  key: 2,
  class: "quantity flex-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_shop_cart_icon = _resolveComponent("shop-cart-icon")!

  return (_openBlock(), _createElementBlock("section", {
    class: _normalizeClass(["cart cursor", { mobile: _ctx.mobile }]),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.route && _ctx.route(...args)))
  }, [
    (_ctx.mobile)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, "Корзина"))
      : (_openBlock(), _createBlock(_component_shop_cart_icon, { key: 1 })),
    (_ctx.productQuantity > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.productQuantity), 1))
      : _createCommentVNode("", true)
  ], 2))
}