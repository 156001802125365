import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_chat_icon = _resolveComponent("chat-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: "chat--button flex-center cursor",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openChat && _ctx.openChat(...args)))
  }, [
    _createVNode(_component_chat_icon)
  ]))
}