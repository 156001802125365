
import { defineComponent } from "vue-demi";
import { LogotypeIcon } from '@/plugins/icons';
import { useAPI } from "@/use";
import { Chat } from "@/interfaces/chat/chat.dto";
import { mapGetters } from "vuex";
import { socketJoinRoom } from "@/socket";

export default defineComponent({
  name: 'CommonChat',
  data() {
    return {
      chat: null as Chat.Dto | null,
      message: ''
    }
  },
  created() {
    this.createMethod();
  },
  mounted() {
    this.jq("body").bind("click", (e) => {
      if (this.jq(e.target).closest(".chat").length === 0 &&
          this.jq(e.target).closest(".chat--button").length === 0)
        this.$store.commit('destroyChat');
    });
    this.onEmits();
  },
  unmounted() {
    this.jq('body').unbind('click');
  },
  computed: {
    ...mapGetters(['user', 'Authorization'])
  },
  methods: {
    async createMethod() {
      const result = await useAPI().chat.getMethod();
      this.chat = result.data as Chat.Dto;
      this.scrollChat(false);
      if (this.chat?.uuid) this.$store.commit('updateUserAuthorization', this.chat.uuid);

      socketJoinRoom(this.socket, this.socket.id, this.chat._id);
    },
    async sendMessage() {
      if (!this.chat || !this.message) return;
      (this.$refs.message as HTMLElement).innerText = '';
      
      await useAPI().chat.createMessageMethod({ content: this.message, chat: this.chat?._id });
      this.scrollChat(false);
    },
    isMyMessage(message: Chat.Message) {
      return (message.uuid || message.user) === (this.user?._id || this.Authorization);
    },
    vModel(event: Event) {
      this.message = (event.target as HTMLInputElement).innerText;
    },
    focusMessageInput() {
      (this.$refs.message as HTMLElement)?.focus();
    },
    scrollChat(animate = true) {
      if (animate) return this.jq('.chat--container').animate({ scrollTop: 99999 }, 500, this.readingMessages);
      return this.jq('.chat--container').animate({ scrollTop: 99999 }, 10, this.readingMessages);
    },
    readingMessages() {
      console.log('reading');
    },
    // Emitter
    onEmits() {
      this.emitter.on('message-action', this.messageActionEmitter);
    },
    offEmits() {
      this.emitter.off('message-action', this.messageActionEmitter);
    },
    messageActionEmitter({ message }: { action: string, message: Chat.Message }) {
      if (!this.chat) return;
      this.chat.messages = [...this.chat.messages, message];
      this.scrollChat(false);
    },
  },
  components: {
    LogotypeIcon
  }
})
