
import { User } from "@/interfaces/user/user.dto";
import { defineComponent, PropType } from "vue-demi";
import { DownArrowIcon, EmptyImageIcon } from '@/plugins/icons';
import { HEADER_PROFILE_LIST } from '@/store/common/drop';
import { currentImageSize } from '@/utils/files';

export default defineComponent({
  name: 'HeaderProfile',
  emits: ['clickElement'],
  props: {
    user: {
      type: Object as PropType<User.Dto>,
    },
    mobile: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showMenu: false,
      dropOptions: {
        id: 'header-profile-action',
        list: HEADER_PROFILE_LIST(this.user?.role),
        border: true,
        width: 160,
      },
    }
  },
  mounted() {
    this.onEmits();
  },
  unmounted() {
    this.offEmits();
  },
  computed: {
    currentUserName() {
      return this.user?.name.split(' ')[1];
    }
  },
  methods: {
    currentImageSize,
    route(route: string) {
      this.$emit('clickElement');
      this.$router.push(route);
    },
    switchShowMenu() {
      this.showMenu = !this.showMenu;
      this.$store.commit('createDropElement', { options: this.dropOptions, target: this.mobile ? `.profile.mobile .logging`  : `.profile` });
    },
    // Emitter
    onEmits() {
      this.emitter.on(`header-profile-action-drop`, this.selectDropElement);
    },
    offEmits() {
      this.emitter.off(`header-profile-action-drop`, this.selectDropElement);
    },
    selectDropElement(element: { _id: string, name: string }) {
      this.$emit('clickElement');
      this.route(element._id);
      this.$store.commit('destroyDropElement');
    },
  },
  components: {
    DownArrowIcon,
    EmptyImageIcon
  },
})
